<script setup>
// layout
import Layout from "../../layouts/main.vue";

// import thư viện
import mushroom from "cem-primary-api";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import { FormInstance } from "element-plus";
import toastr from "toastr";

// data khai báo
import MethodService from "../../service/MethodService";
import Data from "./dataFormQuality";
const ruleFormRef = ref(FormInstance);
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const loadingBtn = ref(false);
const validateFlag = ref(false);
const checkDisabled = ref([
  {
    value: true,
    name: LanguageService?.lang?.t_yes ?? "t_yes",
  },
  {
    value: false,
    name: LanguageService?.lang?.t_no ?? "t_no",
  },
]);

// pinia store
import { onMounted, reactive } from "vue";

const router = useRoute();
const routerPush = useRouter();

// validate form
import LanguageService from "../../service/LanguageService";
import Validate from "../../service/ValidService";
import { langWeb } from "../../stores/lang";
import { watch } from "vue";

const rulesForm = reactive({
  level: [Validate.required],
  threshold_name: [Validate.required],
  lower_threshold: [Validate.required],
  higher_threshold: [Validate.required],
  disabled: [Validate.required],
});

const getQualityLevel = async () => {
  if (router.query.id) {
    try {
      const response = await mushroom.quality_level.findByIdAsync({
        id: router.query.id, // required
      });
      console.log("Thông tin quality level trả về: %o", response);
      dataForm.value = response.result;
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  }
};

const addNewQuality = async (formEl) => {
  validateFlag.value = true;
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (valid) {
      const quality_level_object = {
        level: dataForm.value.level,
        threshold_name: dataForm.value.threshold_name,
        lower_threshold: dataForm.value.lower_threshold,
        higher_threshold: dataForm.value.higher_threshold,
        note: dataForm.value.note,
        disabled: dataForm.value.disabled,
      };
      try {
        const newId = await mushroom.quality_level.createAsync(
          quality_level_object
        );
        console.log("Thêm mới thành công, new id: %o", newId);
        if (newId) {
          toastr.success(t("toastr_add_threshold_success"));
        }
        routerPush.go(-1);
      } catch (error) {
        // if (error.meta.field === "$unique_key") {
        //     toastr.error("Tên ngưỡng đã tồn tại!");
        // }
        toastr.error(t("toastr_add_threshold_fail"));
        console.error("Có lỗi: %o", error);
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

const updateQuality = async (formEl) => {
  validateFlag.value = true;
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (valid) {
      const quality_level_object = {
        id: router.query.id,
        level: dataForm.value.level,
        threshold_name: dataForm.value.threshold_name,
        lower_threshold: dataForm.value.lower_threshold,
        higher_threshold: dataForm.value.higher_threshold,
        note: dataForm.value.note,
        disabled: dataForm.value.disabled,
      };

      try {
        const response = await mushroom.quality_level.partialUpdateAsync(
          quality_level_object
        );
        if (response.result == router.query.id) {
          // console.log("Cập nhật thành công");
          toastr.success(t("toastr_edit_threshold_success"));
        }
        // console.log("Dữ liệu không thay đổi");
        else routerPush.go(-1);
      } catch (error) {
        console.error("Có lỗi: %o", error);
        toastr.error(t("toastr_edit_threshold_fail"));
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    checkDisabled.value = [
      {
        value: true,
        name: LanguageService?.lang?.t_yes ?? "t_yes",
      },
      {
        value: false,
        name: LanguageService?.lang?.t_no ?? "t_no",
      },
    ];
    if (validateFlag.value === true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(() => {
  if (router.name === "UpdateQuality") {
    getQualityLevel();
  }
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'CreateQuality'">
          {{ $t("t-add-quality") }}
        </h3>
        <h3 v-else>{{ $t("t-update-quality") }}</h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            status-icon
            :rules="rulesForm"
            label-width="200px"
            class="form-user"
          >
            <el-form-item :label="$t('t-place-level')" prop="level">
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.level"
                @change="dataForm.value.level = dataForm.value.level.trim()"
                :placeholder="$t('t-place-level')"
              />
            </el-form-item>

            <el-form-item
              :label="$t('t-threshold-name')"
              prop="threshold_name"
            >
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.threshold_name"
                @change="
                  dataForm.value.threshold_name =
                    dataForm.value.threshold_name.trim()
                "
                :placeholder="$t('t-place-quality-name')"
              />
            </el-form-item>

            <el-form-item
              :label="$t('t-lower-threshold')"
              prop="lower_threshold"
            >
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.lower_threshold"
                @change="
                  dataForm.value.lower_threshold =
                    dataForm.value.lower_threshold.trim()
                "
                :placeholder="$t('t-place-lower-threshold')"
              />
            </el-form-item>

            <el-form-item
              :label="$t('t-higher-threshold')"
              prop="higher_threshold"
            >
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.higher_threshold"
                @change="
                  dataForm.value.higher_threshold =
                    dataForm.value.higher_threshold.trim()
                "
                :placeholder="$t('t-place-higher-threshold')"
              />
            </el-form-item>

            <el-form-item :label="$t('t-note')" prop="note">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.note"
                @change="dataForm.value.note = dataForm.value.note.trim()"
                :placeholder="$t('t-place-note')"
              />
            </el-form-item>

            <el-form-item :label="$t('t-disabled')" prop="disabled">
              <el-select
                v-model="dataForm.value.disabled"
                clearable
                :placeholder="$t('t-want-disable')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in checkDisabled"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="routerPush.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="addNewQuality(ruleFormRef)"
                v-if="router.name === 'CreateQuality'"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>

              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="updateQuality(ruleFormRef)"
                :loading="loadingBtn"
                v-else
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.el-radio {
  margin-bottom: 0;
}
</style>
